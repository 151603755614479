<template>
<div>

  <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Add Client">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Person Name"
                  class="required"
                >
                  <b-form-input
                    id="person_name"
                    placeholder="3 to 50 characters"
                    v-model="form.person_name"
                    
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">

                <b-form-group
                  label="Gender"
                  class="required mb-0"
                >


                  <div class="demo-inline-spacing">
                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="male"
                      v-model="form.gender"
                    >
                      Male
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="female"
                      v-model="form.gender"
                    >
                      Female
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Email"
                  class="required"
                >
                  
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="email"
                      type="text"
                      placeholder=""
                      v-model="form.email"
                      
                    />
                  </b-input-group>
                    
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Username"
                  class="required"
                >
                  
                  
                    <b-form-input
                      type="text"
                      placeholder=""
                      v-model="form.username"
                      
                    />
                    
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Password"
                  class="required"
                >

                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    
                    v-model="form.password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                </b-form-group>

              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Phone Number"
                  class="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SmartphoneIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-mobile"
                      type="text"
                      placeholder="Mobile"
                      v-model="form.phone_number"
                      
                    />
                  </b-input-group>
                
                </b-form-group>
              </b-col>

            </b-row>
            

            <b-row class="mb-1 mt-1">

              <b-col md="12">
                <h4> Organization Detail</h4>
              </b-col>

              
            </b-row>

            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Organization Name"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="up to 50 characters" 
                    v-model="form.organization_name"
                    
                  />
                    
                </b-form-group>
              </b-col>
              
              <b-col md="6">
                <b-form-group label="Company Registration No.">
                  <b-form-input placeholder="up to 8-16 characters" v-model="form.registration_number"/>
                </b-form-group>
              </b-col>
              
              <b-col md="6">
                <b-form-group label="GST Registration No.">
                  <b-form-input placeholder="up to 10-16 characters" v-model="form.gst_registration_number"/>
                </b-form-group>
              </b-col>
              
              <b-col md="6">
                <b-form-group label="Fax">
                  <b-form-input placeholder="up to 8 characters" v-model="form.fax"/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Address"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="up to 100 characters"
                    v-model="form.organization_address"
                  />
                    
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Postal Code"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="6 digits" 
                    v-model="form.organization_postal_code"
                    
                  />
                    
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Country"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="up to 50 characters"
                    v-model="form.organization_country"
                  />
                    
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-1 mt-1">

              <b-col md="12">
                <h4> Organization Billing Detail</h4>
              </b-col>

              
            </b-row>

            <b-row>

              <b-col md="12" class="mb-1 mt-1">
                  <b-form-checkbox
                    v-model="form.same_address"
                    value="yes"
                    unchecked-value="no"
                    class="custom-control-primary"
                    @change="updateAddress($event)"
                  >
                    Same As Organization Address
                  </b-form-checkbox>
                  
              </b-col>
            </b-row>

            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Address"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="up to 100 characters"
                    v-model="form.billing_address"
                  />
                    
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Postal Code"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="6 digits" 
                    v-model="form.billing_postal_code"
                    
                  />
                    
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Country"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="up to 50 characters"
                    v-model="form.billing_country"
                  />
                    
                </b-form-group>
              </b-col>
            </b-row>

            <hr>

          <!-- Menu Permission Design Starts -->
          <b-row>
              <b-col md="12">
                <h4 class="mb-2 mt-1"> Menu Permission</h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="8">
                <b-form-checkbox class="custom-control-warning" @change="selectAllMenu">Select All </b-form-checkbox>
                <hr>
                <!-- ---------------- new implementattion starts --------------- <br/>  -->

                      <div v-for="(menu, index) in items" :key="index">
                        <div>
                          <b-form-checkbox class="custom-control-warning mt-1" v-model="menu.access" @change="toggleChild(menu)">{{menu.title}}</b-form-checkbox>
                          
                          <!-- level 1 menu -->
                          <div class="pl-2 row" v-if="menu.level == 1">
                            <div class="col-4 pt-1" v-for="(permission, ip) in menu.permissions" :key="ip">
                              <b-form-checkbox v-model="permission.access" @change="toggleParent(menu)">{{permission.label}}</b-form-checkbox>
                            </div>
                          </div>

                          <!-- level 2 menu -->
                          <div v-if="menu.level == 2">
                            <div v-for="(child, ch) in menu.children" :key="ch">
                              
                              <div class="pl-2">
                                <div>
                                  <b-form-checkbox class="mt-2" v-model="child.access" @change="toggleChild(child); toggleParent(menu)">{{child.title}}</b-form-checkbox>
                                  <div class="pl-2 row">
                                    <div class="col-4 pt-1" v-for="(permission, ip) in child.permissions" :key="ip">
                                      <b-form-checkbox v-model="permission.access" @change="toggleParent(child); toggleParent2(menu);">{{permission.label}}</b-form-checkbox>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                          </div>

                          <!-- level 3 menu -->
                          <div v-if="menu.level == 3">
                            <div v-for="(child, ch) in menu.children" :key="ch">
                              
                              <div class="pl-2">
                                <div>
                                  <b-form-checkbox class="mt-2" v-model="child.access" @change="toggleChild(child); toggleParent(menu)">{{child.title}}</b-form-checkbox>
                                  
                                  <!-- having permission -->
                                  <div class="pl-2 row" v-if="child.permissions">
                                    <div class="col-4 pt-1" v-for="(permission, ipp) in child.permissions" :key="ipp">
                                      <b-form-checkbox v-model="permission.access" @change="toggleParent(child); toggleParent2(menu);">{{permission.label}}</b-form-checkbox>
                                    </div>
                                  </div>
                                  
                                  <!-- having child -->
                                  <div class="pl-2 row" v-if="child.children">
                                    <div class="col-12 pt-1" v-for="(ch, ic) in child.children" :key="ic">
                                      <b-form-checkbox v-model="ch.access" @change="toggleParent(child); toggleParent(menu); toggleChild(ch)">{{ch.title}}</b-form-checkbox>
                                        <!-- permissions of child -->
                                        <div class="pl-2 row" v-if="ch.permissions">
                                          <div class="col-4 pt-1" v-for="(pm, ipc) in ch.permissions" :key="ipc">
                                            <b-form-checkbox class="custom-control-primary" v-model="pm.access" @change="toggleParent(ch); toggleParent(child); toggleParent(menu); toggleParent2(ch); toggleParent2(child); toggleParent2(menu);">{{pm.label}}</b-form-checkbox>
                                          </div>
                                        </div>

                                    </div>
                                  </div>

                                </div>
                              </div>
                              
                            </div>
                          </div>

                          <hr v-if="index != (items.length - 1)">
                          
                        </div>
                      </div>

              </b-col>
            </b-row>
          <!-- Menu Permission Design Ends -->

            <b-row>
              <b-col md="12">
                <hr />
                <h4 class="mb-2 mt-1">Assign Custom Forms</h4>
              </b-col>
            </b-row>
            
            <b-row>
              <b-col md="12">
                <b-form-group
                    label="Forms"
                  >
                    
                    <v-select
                      v-model="form.custom_forms"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      multiple
                      :options="custom_forms"
                      placeholder="Select"
                      @input="checkforms($event)"
                      class="multiselect_muliple_options"
                    />
                  </b-form-group>
              </b-col>

            </b-row>

            <hr>

            <b-row class="mb-1 mt-1">

              <b-col md="12">
                <h4> Organization Media Detail</h4>
              </b-col>

              
            </b-row>

            <b-row>
            <b-col md="4">

              <b-form-group
                label="Company Logo"
                class="required"
              >
                <b-media class="mb-2 mt-2" @click="$refs.refInputEl.click()">
                  <template #aside>
                    <b-avatar
                      ref="company_logo"
                      :src="form.companyLogo.image != '' ?form.companyLogo.image : doc_icon"
                      variant="light-primary"
                      size="200px"
                      rounded
                    />
                  </template>

                  <div class="d-flex flex-wrap">
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      
                      @input="companyLogoRenderer"
                    >
                  </div>
                </b-media>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <CompanyLetterHead v-bind:companyLetterHead="form.companyLetterHead"/>
            </b-col>

            <!-- <b-col md="4">
              <b-form-group
                label="Company App Logo"
                class="required"
              >
                <b-media class="mb-2 mt-2" @click="$refs.refInputEl2.click()">
                  <template #aside>
                    
                    <b-avatar
                      ref="company_app_logo"
                      :src="form.companyAppLogo.image != '' ?form.companyAppLogo.image : doc_icon"
                      variant="light-danger"
                      size="200px"
                      rounded
                      
                    />

                  </template>
                  <div class="d-flex flex-wrap">
                    <input
                      ref="refInputEl2"
                      type="file"
                      class="d-none"

                      @input="companyAppLogoRenderer"
                    >
                  </div>
                </b-media>
              </b-form-group>
            </b-col> -->
            

          </b-row>

          <b-row>
              <b-col cols="4" class="mb-2 mt-2"> 

                  <b-row>
                      <b-col cols="12" class="mb-2 mt-2">
                          <span class="mb-0"> Signature </span>
                      </b-col>
                  </b-row>

                  <b-row>
                      <b-col cols="12" class="mb-2 mt-0"> 
                          <b-media class="mb-2 mt-2" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">
                              
                              <template #aside>
                                  <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded />
                              </template>

                              <div class="d-flex flex-wrap">
                                  <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                              </div>

                          </b-media>

                          <img :src="supervisorSign.image" width="200px" height="180" v-if="supervisorSign.image != null" class="rounded" style="background: #ffffff">
                          <br />
                          <!-- <center> -->
                              <b-button variant="danger" class="mt-1" style="margin-left:20%" v-if="supervisorSign.image != null" @click="removeSign('supervisor')">
                                  <feather-icon icon="XIcon" />
                              </b-button>
                          <!-- </center> -->
                      </b-col>
                  </b-row>

              </b-col>
          </b-row>

            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name: 'client-list'})"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  <b-modal id="crop-modal" ref="crop-modal" centered title="Edit Profile Image" hide-footer 
      :no-close-on-backdrop="true" @hide="resetModal">
      
        <template>
              <div>
                
                <div class="">
                  <section class="cropper-area">

                    <div class="img-cropper">

                     <vue-cropper ref="cropper" :src="temp.image" />
                      
                    </div>

                    <div class="pt-2 text-center float-left">
                      
                      <b-button variant="primary" class="mr-2 text-capitalize" @click.prevent="cropImage">CROP</b-button>

                      <b-button variant="danger" class="mr-2 text-capitalize" @click.prevent="reset">RESET</b-button>

                    </div>
                  </section>
                </div>
              </div>
        </template>
     
    </b-modal>

    <b-modal centered hide-footer
            id="signatureModel"
            ref="signatureModel"
            title="Signature"
            no-close-on-backdrop
            @hide="hideSiganture"
            @show="hideSiganture"
        >
        <b-row>
            <b-col md="12">
                <VueSignaturePad
                    id="signature"
                    width="100%"
                    height="300px"
                    ref="signaturePad"
                    :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3 }"
                />
            </b-col>
        </b-row>

        <b-row class="mt-2">

            <b-col md="4">
                <b-button variant="primary" class="mt-0 w-100" @click="uploadSign()">
                    <span class="text-nowrap">Upload</span>
                </b-button>
            </b-col>

            <!-- <b-col md="3">
                <b-button variant="secondary" class="mt-0" @click="useDefault()">
                    <span class="text-nowrap">Default</span>
                </b-button>
            </b-col> -->

            <b-col md="4">
                <b-button variant="danger" class="mt-0 w-100" @click="clearSignature">
                    <span class="text-nowrap">Clear</span>
                </b-button>
            </b-col>

            <b-col md="4">
                <b-button variant="warning" class="mt-0 w-100" @click="saveSignature">
                <span class="text-nowrap">Submit</span>
                </b-button>
            </b-col>
            
        </b-row>
    </b-modal>
</div>
</template>

<script>

import {
  BBreadcrumb, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BInputGroupAppend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import vSelect from 'vue-select';
import CompanyLetterHead from './CompanyLetterHead.vue';
import Bus from "../../../event-bus";
export default {
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BInputGroupAppend,
    vSelect,
    BBreadcrumb,
    CompanyLetterHead
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      temp:{
        image:null,
        name:'',
      },

      doc_icon: require('@/assets/images/doc.png'),
      error_message:null,
      showDismissibleAlert:false,
      form:{
        person_name : '',
        gender : '',
        email : '',
        username:'',
        password:'',
        phone_number : '',
        organization_name : '',
        organization_address :'',
        organization_postal_code : '',
        organization_country :'',

        registration_number : '',
        gst_registration_number : '',
        fax : '',

        billing_address :'',
        billing_postal_code : '',
        billing_country :'',
        company_letter_head_thumbnail:'',
        companyLetterHead : {
          image: '',
          name:''
        },
        companyLogo : {
          image: '',
          name:''
        },
        companyAppLogo : {
          image: '',
          name:''
        },
        same_address:'no',
        assign_menus : [],
        custom_forms:[],
      },
      modelbox:'',
      items:null,
      custom_forms:[],

      // signature vars
      doc_icon: require('@/assets/images/doc.png'),
      supervisorSign : {
          image:null,
          name:'',
          type: 'supervisor',
          default:'no'
      },
      openedSignModel:null,
      custom_form_status:false,
    }
  },
  methods : {
    showCustomForm(){
      var cf1 = false;
      var cf2 = false;

      this.items.forEach(el => {
        if(el.level == 1 && el.title == 'Custom Forms'){
          cf1 = el.access;
        }
        if(el.level == 2 && el.title == 'Reports'){
          el.children.forEach(ch => {
            if(ch.title == 'Custom Forms'){
              cf2 = ch.access;
            } 
          })
        }
      });

      if((cf1 == false) && (cf2 == false)){
        // return Promise.reject('Please assign custom forms');
        this.form.custom_forms = [];
      } else {
        this.custom_form_status = true;
      }
    },

    formSubmit(e){
      e.preventDefault();

      // assign menus
      this.form.assign_menus = this.items;

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form,
             supervisorSign : this.supervisorSign,
           },
           api: '/api/add-client'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Added Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name: 'client-list'});
                });
                
            }
        });
      
    },

    showImg(){
      this.$refs['crop-modal'].show();
    },
    resetModal(){
      
      if (this.modelbox == 'companyLogo') {

        this.$refs.refInputEl.value=null;

      }else if(this.modelbox == 'companyAppLogo'){

        this.$refs.refInputEl2.value=null;

      }else if(this.modelbox == 'profileImage'){

        this.$refs.profileInput.value=null;

      }
      

      this.temp.image = null;
      this.temp.name="";

      this.$refs['crop-modal'].hide();
    },
    cropImage() {
      
      if (this.modelbox == 'companyLogo') {

        this.form.companyLogo.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.companyLogo.name = this.temp.name;

      }else if(this.modelbox == 'companyAppLogo'){

        this.form.companyAppLogo.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.companyAppLogo.name = this.temp.name;

      }else if(this.modelbox == 'profileImage'){

        this.form.profileImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.profileImage.name = this.temp.name;

      }
      
      this.$refs['crop-modal'].hide(); 
      
    },
    reset() {
      this.$refs.cropper.reset();
    },
    companyLogoRenderer(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;
          } else {
              reader.onload = (e) => {
                  this.temp.image = e.target.result;
                  this.temp.name = image.name;
                  this.modelbox = 'companyLogo';

                  this.showImg();
            }
          }
      }
    },
    companyAppLogoRenderer(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;
          } else {
              reader.onload = (e) => {
                  this.temp.image = e.target.result;
                  this.temp.name = image.name;
                  this.modelbox = 'companyAppLogo';

                  this.showImg();
            }
          }
      }
    },
    /*profileImageRenderer(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;
          } else {
              reader.onload = (e) => {
                  this.temp.image = e.target.result;
                  this.temp.name = image.name;
                  this.modelbox = 'profileImage';

                  this.showImg();
            }
          }
      }
    },*/
    updateAddress(e){
      if (e == 'yes') {
        
        this.form.billing_address =this.form.organization_address;
        this.form.billing_postal_code =this.form.organization_postal_code;
        this.form.billing_country =this.form.organization_country;

      }else{

        this.form.billing_address = '';
        this.form.billing_postal_code ='';
        this.form.billing_country ='';

      }
    },
    // added for menu permission 
    getMenus(){
        return this.$store.dispatch(POST_API, {
            data:{
              user_type:'admin'
            },
            api: '/api/get-menus'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.items = this.$store.getters.getResults.data;
                
                return this.items;
            }
        }); 
    },

    selectAllMenu(e){
      if(this.items){
        this.items.forEach(menu => {
          if(menu.level == 1){
            this.forParent(menu.permissions, e);
          } 
          else if (menu.level == 2) {
            this.forChild(menu.children, e);
          }
          else if (menu.level == 3) {
            this.forChildL3(menu.children, e);
          }
          menu.access = e;
        });

        this.showCustomForm();

      }
    },

    toggleChild(permissions){
      if(permissions.permissions){
        this.forParent(permissions.permissions, permissions.access);
      } else {
        this.forChild(permissions.children, permissions.access);
      }

      this.showCustomForm();      
    },

    forParent(menu, access){
      menu.forEach(function(item){
        item.access = access;
      }); 
    },

    forChild(menu, access){
      menu.forEach(function(item){
        item.access = access;
        if(item.permissions){
          item.permissions.forEach(function(inner_item){
            inner_item.access = access;
          }); 
        } else {
          item.children.forEach(function(ch){
            ch.access = access;
            ch.permissions.forEach(function(sci){
              sci.access = access;
            }); 
          });
        }

      });
    },
    
    forChildL3(menu, access){
      menu.forEach(function(item){
        item.access = access;

        if(item.permissions){
          item.permissions.forEach(function(pm){
            pm.access = access;
          }); 
        } else {
          item.children.forEach(function(ch){
            ch.access = access;
            ch.permissions.forEach(function(inner_item){
              inner_item.access = access;
            }); 
          });
        }
        
      });
    },

    toggleParent(menu){
      var flag = false;

      if(menu.permissions){
        menu.permissions.forEach(item => {
          if(item.access == true){
            flag = true;
          }
        });
      } else {
        menu.children.forEach(item => {
          if(item.access == true){
            flag = true;
          }
        });
      }
      menu.access = flag;

      this.showCustomForm();
    },

    toggleParent2(menu,){
      var flag = false;
      menu.children.forEach(item => {
        if(item.permissions){
          item.permissions.forEach(elem => {
            if(elem.access == true){
              flag = true;
            }
          });
        } 
        else if(item.children){

          item.children.forEach(ch => {
            if(ch.permissions){

              ch.permissions.forEach(sch => {
                if(sch.access == true){
                  flag = true;
                }
              })

            }
          })

        }

      });
      menu.access = flag;
    },

    // signature functions
      hideSiganture(){
          this.openedSignModel = null;
      },

      openSignature(type){
          if (this.openedSignModel == null) {
              this.$refs['signatureModel'].show();
              this.openedSignModel = type;
          }
      },

      uploadSign(){                
          if (this.openedSignModel == 'supervisor') {
              this.$refs['supervisorSInput'].click();
          }
          if (this.openedSignModel == 'client') {
              this.$refs['clientSInput'].click();
          }
          this.$refs['signatureModel'].hide();
      },

      clearSignature(){
          this.$refs.signaturePad.clearSignature();
      },

      saveSignature(){
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          if (isEmpty) {
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Signature is required',
                  showConfirmButton: false,
                  timer: 1500
              })
          }else{
              if (this.openedSignModel == 'supervisor') {
                  this.supervisorSign.image = data;
                  this.supervisorSign.name = 'signature.png';
                  this.supervisorSign.type =  'supervisor';
                  this.supervisorSign.default = 'no';
              } else {
                  this.clientSign.image = data;
                  this.clientSign.name = 'signature.png';
              }
              this.$refs['signatureModel'].hide();
          }
      },

      supervisorImageUpload(event){
          var input = event.target;
          var files = event.target.files

          if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var image = input.files[0];

              if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                  Swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: 'Please upload .png, .jpeg, .jpg images only',
                      showConfirmButton: false,
                      timer: 1500
                  });

                  this.$refs.supervisorSInput.value=null;

              } else if (image.size > 2097152) {
                  Swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: 'Maximum 2 MB files allowed to be upload.',
                      showConfirmButton: false,
                      timer: 1500
                  })
                  this.$refs.supervisorSInput.value=null;
              } else {
                  reader.onload = (e) => {
                      this.supervisorSign.image = e.target.result;
                      this.supervisorSign.name = image.name;
                      this.supervisorSign.type =  'supervisor';
                      this.supervisorSign.default = 'no';
                  }
              }
          }
      },
          
      useDefault(){
          return this.$store.dispatch(POST_API, {
              data:{
                  id:this.$store.getters.currentUser._id
              },
              api: '/api/get-default-signature'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data  = this.$store.getters.getResults.data;
                  
                  if (data == null || data.default_signature == null) {
                      Swal.fire({
                          position: 'center',
                          icon: 'warning',
                          title: 'Default signature not found.',
                          showConfirmButton: false,
                          timer: 1500
                      })
                  } else {

                      if (this.openedSignModel == 'supervisor') {
                          this.supervisorSign = {
                              image : data.default_signature,
                              name : 'siganture.png',
                              type: 'supervisor',
                              default:'yes'
                          }
                      }
                      this.$refs['signatureModel'].hide();
                  }
              }
          });

      },
      removeSign(sign){
          this.supervisorSign.image = null;
          this.supervisorSign.name = '';
          this.supervisorSign.default = 'no';
      },
      customForms(){
        return this.$store.dispatch(POST_API, {
            data:{
              
            },
            api: '/api/get-custom-forms'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.custom_forms = this.$store.getters.getResults.data;
                
                if (this.custom_forms.length > 0) {
                  
                  var obj = {
                    _id:'all-forms',
                    title:'All Forms'
                  }


                  this.custom_forms.unshift(obj);

                }
                return this.custom_forms;
            }
        }); 
      },
      checkforms(e){
        

        if (e.length > 0 && e[e.length-1]._id == 'all-forms') {
          
          this.form.custom_forms = [];
          
          this.custom_forms.forEach(item => {
            if (item._id != 'all-forms') {
              this.form.custom_forms.push(item);
            }
          })
        }
      },

      breadCrumb(){
        var item = [{
          to:{name:'admin-dashboard'},
          text: 'Dashboard',
        },{
          to:{name:'client-list'},
          text: 'Client',
        },{
          to:null,
          text: 'Add Client',
          active:true             
        }];
        return item;
      },

  },
  mounted(){
    this.getMenus();
    this.customForms();

    Bus.$on('update-company-letter-head', (data) => {
        this.form.companyLetterHead = data.data;
    })
  }
}
</script>
